import { useLingui } from '@lingui/react';
import React, { Fragment } from 'react';
import { getTableValue } from '../../../utils/data.utils';
import SectionTitle from '../../SectionTitle';
import './TwoColsTemplateTable.css';

const TwoColsTemplateTable = ({ template, level }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  console.log(template);

  //#region [render]
  return template ? (
    <div>
      {template.title && (
        <SectionTitle level={level}>{template.title}</SectionTitle>
      )}
      <table className='template-table two-cols-table'>
        <tbody>
          {template.rows.map((row, index) => {
            const value = getTableValue(
              i18n,
              row.value,
              row.unit,
              row.precision
            );
            return (
              <Fragment key={'template_row_' + row.label + '_' + index}>
                {!row.hidden && (
                  <tr>
                    <td
                      className={`table-label${
                        row.labelClassName ? ` ${row.labelClassName}` : ''
                      }`}
                      title={row.label ?? undefined}
                    >
                      {row.label}
                    </td>
                    <td
                      className={`table-value${
                        row.valueClassName ? ' ' + row.valueClassName : ''
                      }`}
                      title={value ?? undefined}
                    >
                      {value}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : null;
  //#endregion
};

export default React.memo(TwoColsTemplateTable);
