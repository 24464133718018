import { useLingui } from '@lingui/react';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TAG } from '../../../../server/constants';
import SelectBtn from '../SelectBtn/SelectBtn';
import PopupContext from '../../contexts/PopupContext';
import './Tags.css';

const Tags = ({ currentObjectId, currentObjectTags, getCurrentTaggedObjectId, onTagAdd, onTagRemove, onTagReplace }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { projectId } = useParams();
  //#endregion

  //#region [contexts]
  const { openConfirmModal } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleTagChange = async (tag) => {
    currentObjectTags[tag.key]
      ? await onTagRemove(currentObjectId, tag)
      : await onTagAdd(currentObjectId, tag);
  };

  const handleUniqueTagChange = async (tag) => {
    if (currentObjectTags[tag.key]) {
      await onTagRemove(currentObjectId, tag);
    } else {
      // on vérifie que l'un des calculs ne possède déjà pas le tag
      const currentTaggedObjectId = await getCurrentTaggedObjectId(projectId, tag.value);
      if (!currentTaggedObjectId) {
        // il n'existe pas de calcul avec ce tag, on l'ajoute
        await onTagAdd(currentObjectId, tag);
      } else {
        // il existe déjà un calcul avec ce tag, on demande à l'utilisateur si il est sûr de vouloir le remplacer
        openConfirmModal(
          i18n._('results.general.tagModal.title', {
            tag: i18n._(`tag.${tag.key}`)
          }),
          i18n._('results.general.tagModal.body', {
            tag: i18n._(`tag.${tag.key}`)
          }),
          'info',
          async () => {
            await onTagReplace(currentObjectId, currentTaggedObjectId, tag);
          }
        );
      }
    }
  };
  //#endregion

  //#region [render]
  return (
    <div className='tags-selector'>
      <SelectBtn
        selected={currentObjectTags[TAG.FEASIBILITY.key]}
        color='#62afdc'
        onClick={async () => await handleTagChange(TAG.FEASIBILITY)}
      >
        {i18n._('tag.feasibility')}
      </SelectBtn>
      <SelectBtn
        selected={currentObjectTags[TAG.PROJECT.key]}
        color='#828282'
        onClick={async () => await handleUniqueTagChange(TAG.PROJECT)}
      >
        {i18n._('tag.project')}
      </SelectBtn>
      <SelectBtn
        selected={currentObjectTags[TAG.CONTRACT_COMMITMENT.key]}
        color='#60c088'
        onClick={async () =>
          await handleUniqueTagChange(TAG.CONTRACT_COMMITMENT)
        }
      >
        {i18n._('tag.contractCommitment')}
      </SelectBtn>
    </div>
  );
  //#endregion
};

export default Tags;
