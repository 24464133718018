import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faCalendar,
  faFaceRollingEyes,
  faCircle as regularCircle,
  faCircleQuestion as regularCircleQuestion,
  faPenToSquare as regularPenToSquare
} from '@fortawesome/free-regular-svg-icons';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowUp,
  faArrowUpFromWaterPump,
  faBackwardStep,
  faBars,
  faBuilding,
  faBullseye,
  faCalculator,
  faCaretDown,
  faCaretRight,
  faCaretSquareDown,
  faCaretSquareRight,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleDot,
  faCircleInfo,
  faCircleQuestion,
  faClipboardList,
  faComment,
  faCompassDrafting,
  faDownload,
  faDrawPolygon,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFaceFrown,
  faFan,
  faFileCode,
  faFileCsv,
  faFire,
  faFloppyDisk,
  faForwardStep,
  faHouse,
  faList,
  faListCheck,
  faMagnifyingGlass,
  faMinusCircle,
  faPenToSquare,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faPowerOff,
  faRobot,
  faSadCry,
  faSearchLocation,
  faSnowflake,
  faStopwatch,
  faSun,
  faTable,
  faTrashAlt,
  faTrashCanArrowUp,
  faTriangleExclamation,
  faUpload,
  faUser,
  faUserGroup,
  faUserShield,
  faWater,
  faWrench,
  faXmark,
  faCircle as solidCircle
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  faMinusCircle,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faTrashAlt,
  faArrowLeft,
  faCircleQuestion,
  faFileCsv,
  faExclamationCircle,
  faBars,
  faPowerOff,
  faArrowRight,
  faExclamationTriangle,
  faCheckCircle,
  faPenToSquare,
  faArrowDown,
  faArrowUp,
  faCaretDown,
  faCaretRight,
  faCheck,
  faCaretSquareDown,
  faCaretSquareRight,
  faChartLine,
  faCalendar,
  faCalculator,
  faXmark,
  faFaceFrown,
  faComment,
  faSearchLocation,
  faMagnifyingGlass,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faTrashCanArrowUp,
  faCircleArrowLeft,
  faCircleArrowRight,
  faStopwatch,
  faEye,
  faWrench,
  faSadCry,
  faFloppyDisk,
  faTable,
  faDownload,
  faUpload,
  faUserGroup,
  faUserShield,
  faUser,
  faFaceRollingEyes,
  faCircleInfo,
  faListCheck,
  faList,
  faCircleDot,
  faHouse,
  faBuilding,
  faArrowRightArrowLeft,
  faWater,
  faFire,
  faArrowUpFromWaterPump,
  faFan,
  faClipboardList,
  faCompassDrafting,
  faRobot,
  faDrawPolygon,
  faSnowflake,
  faSun,
  regularCircleQuestion,
  faEyeSlash,
  faFileCode,
  regularCircle,
  solidCircle,
  regularPenToSquare,
  faBullseye,
  faBackwardStep,
  faForwardStep,
  faTriangleExclamation
);
