import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useState } from 'react';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import GeostorageRows from '../../components/GeostorageRows';
import HeatpumpsRows from '../../components/HeatpumpsRows';
import './EquipmentListSection.css';

const EquipmentListSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [states]
  const [subEquipementList, setSubEquipementList] = useState([]);
  //#endregion

  //#region [effects]
  useEffect(() => {
    return setSubEquipementList([...setAllSubEquipmentRows()]);
  }, []);
  //#endregion

  //#region [methods]
  const setAllSubEquipmentRows = () => {
    const { heatpumps, geostorage } = config.ConfigsSst[0].Data;
    // on crée la liste des lignes dépliables dans le tableau
    const rows = [];
    // on ajoute les modules des PACs
    heatpumps.list.forEach((hp) => {
      if (hp.lowerModule) {
        rows.push(`${hp.position}_${hp.lowerModule.name}`);
      }
      rows.push(`${hp.position}_${hp.upperModule.name}`);
    });
    // on ajoute les modules bas captage et injection
    if (geostorage.lowerModules) {
      Object.values(geostorage.lowerModules).forEach((lowerModule) => {
        if (lowerModule) {
          rows.push(`${lowerModule.name}`);
        }
      });
    }
    return rows;
  };

  const handleShowSubEquipment = (name) => {
    return setSubEquipementList(
      subEquipementList.includes(name)
        ? subEquipementList.filter((currentEq) => currentEq !== name)
        : subEquipementList.concat(name),
      [...subEquipementList]
    );
  };

  const handleShowAllSubEquipment = () => {
    if (subEquipementList.length > 0) {
      setSubEquipementList([]);
    } else {
      setSubEquipementList([...setAllSubEquipmentRows()]);
    }
  };
  //#endregion

  //#region [render]
  const { heatpumps, geostorage } = config.ConfigsSst[0].Data;
  return (
    <Section title={i18n._('config.equipmentList.title')} level={2} open>
      <p>{i18n._('config.equipmentList.description.text')}</p>
      <div className='custom-table-wrapper'>
        <table className='equipment-list-table'>
          <thead>
            <tr>
              <th colSpan={2}></th>
              <th colSpan={2}>
                {i18n._('config.equipmentList.hydraulicEquipment.accenta')}
              </th>
              <th colSpan={5}>
                {i18n._('config.equipmentList.modules.manufacturing')}
              </th>
              <th></th>
            </tr>
            <tr>
              <th>
                <FontAwesomeIcon
                  className='equipment-list-chevron'
                  onClick={handleShowAllSubEquipment}
                  icon={
                    subEquipementList.length > 0
                      ? faChevronDown
                      : faChevronRight
                  }
                />
              </th>
              <th className='equipment-list-equipment-name'>
                {i18n._('config.equipmentList.equipment')}
              </th>
              <th></th>
              <th>{i18n._('config.equipmentList.total')}</th>
              <th>{i18n._('config.equipmentList.total')}</th>
              <th>{i18n._('config.equipmentList.hydraulicAndStructure')}</th>
              <th>{i18n._('config.equipmentList.factoryInsulation')}</th>
              <th>{i18n._('config.equipmentList.siteInsulation')}</th>
              <th>{i18n._('config.equipmentList.config')}</th>
              <th>{i18n._('config.equipmentList.total')}</th>
            </tr>
          </thead>
          <tbody>
            <HeatpumpsRows
              hpsList={heatpumps.list}
              subEquipementList={subEquipementList}
              handleShowSubEquipment={handleShowSubEquipment}
            />
            <GeostorageRows
              subEquipementList={subEquipementList}
              handleShowSubEquipment={handleShowSubEquipment}
            />
          </tbody>
        </table>
      </div>
    </Section>
  );
  //endregion
};
export default EquipmentListSection;
