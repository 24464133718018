import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import Section from '../../../../../../../components/Section/Section';
import TwoColsTemplateTable from '../../../../../../../components/TemplateTables/TwoColsTemplateTable/TwoColsTemplateTable';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import './HeadLossSummarySection.css';
import headlossTemplate from './templates';
import { Alert } from 'react-bootstrap';
import { HEADLOSS_WARNINGS } from '../../../../../../../../../server/constants';

const HeadLossSummarySection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [memos]
  const HTemplates = useMemo(() => {
    return headlossTemplate(i18n, config.ConfigsSst[0]);
  }, [config]);
  //#endregion

  //#region [render]
  const headLossTotal = HTemplates.rows[2].value; // pertes de charges totales
  return (
    <Section
      title={i18n._('config.geostorage.headLoss.summary')}
      level={2}
      open
      className='headLoss-summary-section'
    >
      {headLossTotal > HEADLOSS_WARNINGS.MAX_GEOSTORAGE_TOTAL_HEADLOSS && (
        <Alert variant='warning' className='mb-3'>
          {i18n._('config.geostorage.headLoss.summary.warning')}
        </Alert>
      )}

      <TwoColsTemplateTable template={HTemplates} />
    </Section>
  );
  //#endregion
};

export default HeadLossSummarySection;
