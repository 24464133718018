import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { cloneDeep } from 'lodash';
import React, { useContext, useState } from 'react';
import { Nav, NavDropdown, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { MAX_DESCRIPTIONS } from '../../../../server/constants';
import {
  ENERGY_STORAGE,
  GEOSTORAGE,
  getColdProductionParams,
  getHeatProductionParams,
  HOTWATER_PRODUCTION,
  STD_COLS,
  WEATHER_COLS
} from '../../../../server/models/design/description.model';
import { EMPTY_PROJECT } from '../../../../server/models/project.model';
import { EMPTY_SUBSTATION } from '../../../../server/models/substation.model';
import { deleteProject, deleteSstParent } from '../../api/project.api';
import { fetchStdFile } from '../../api/stdFile.api';
import { fetchWeatherFile } from '../../api/weatherFile.api';
import PopupContext from '../../contexts/PopupContext';
import { bufferToArray } from '../../utils/csv.utils';
import { getSummaryData } from '../../utils/substation.utils';
import DescriptionTab from './DescriptionTab/DescriptionTab';
import './DescriptionsTabs.css';

const DEFAULT_ACTIVE_TAB = '0';
const DescriptionsTabs = ({
  forms,
  weatherForms,
  stdForms,
  onFormsChange,
  onWeatherFormsChange,
  onStdFormsChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { companyId } = useParams();
  //#endregion

  //#region [contexts]
  const { openConfirmModal, openErrorToast, openToast } =
    useContext(PopupContext);
  //#endregion

  //#region [states]
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
  //#endregion

  //#region [methods]
  const getDuplicateWeatherForm = async (
    projectIdToDuplicate,
    newProjectId
  ) => {
    try {
      const weatherFormToDuplicate = weatherForms.find(
        (f) => f.projectId === projectIdToDuplicate
      );
      const file =
        weatherFormToDuplicate.file ??
        (await fetchWeatherFile(weatherFormToDuplicate.fileId));
      return {
        projectId: newProjectId,
        file,
        fileId: crypto.randomUUID(),
        filename: weatherFormToDuplicate.filename
      };
    } catch (err) {
      throw err;
    }
  };

  const getDuplicateStdForm = async (
    sstIdToDuplicate,
    newProjectId,
    newSstId
  ) => {
    try {
      const stdFormToDuplicate = stdForms.find(
        (f) => f.sstId === sstIdToDuplicate
      );
      const file =
        stdFormToDuplicate.file ??
        (await fetchStdFile(stdFormToDuplicate.fileId));
      return {
        projectId: newProjectId,
        sstId: newSstId,
        file: file,
        fileId: crypto.randomUUID(),
        filename: stdFormToDuplicate.filename,
        summaryData: stdFormToDuplicate.summaryData
      };
    } catch (err) {
      throw err;
    }
  };

  const handleDuplicateFormClick = async (form) => {
    try {
      const formsCount = forms.length;
      const duplicateForm = cloneDeep(form);
      const newProjectId = crypto.randomUUID();
      duplicateForm.AhsID = newProjectId;
      duplicateForm.ParentAhsID = forms[0].AhsID;

      // on récupère le fichier météo de la description qu'on duplique
      const weatherForm = await getDuplicateWeatherForm(
        form.AhsID,
        newProjectId
      );
      duplicateForm.WeatherFileID = weatherForm.fileId;

      let sstFormIndex = 0;
      const duplicateStdForms = [];
      for await (const sstForm of duplicateForm.substations) {
        const newSstId = crypto.randomUUID();
        sstForm.AhsID = newProjectId;
        sstForm.InitStationID = newSstId;
        sstForm.ParentInitStationID =
          forms[0].substations[sstFormIndex].InitStationID;

        // on récupère le fichier STD de chaque sous-station qu'on duplique
        const stdForm = await getDuplicateStdForm(
          form.substations[sstFormIndex].InitStationID,
          newProjectId,
          newSstId
        );
        duplicateStdForms.push(stdForm);
        sstForm.StdFileID = stdForm.fileId;
        sstFormIndex++;
      }
      onFormsChange([...forms, duplicateForm]);
      onWeatherFormsChange([...weatherForms, weatherForm]);
      onStdFormsChange([...stdForms, ...duplicateStdForms]);
      setActiveTab(() => '' + formsCount);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleNewFormClick = () => {
    try {
      const formsCount = forms.length;
      const newForm = cloneDeep(forms[0]);
      Object.keys(forms[0])
        .filter((key) => key !== 'substations')
        .forEach((key) => {
          newForm[key] =
            key in EMPTY_PROJECT
              ? EMPTY_PROJECT[key]
              : EMPTY_PROJECT.otherValues[key];
        });
      const newProjectId = crypto.randomUUID();
      newForm.AhsID = newProjectId;
      newForm.ParentAhsID = forms[0].AhsID;
      newForm.AhsCoID = companyId;
      newForm.WeatherFileID = null;
      newForm.InitTemperatureHeatBack =
        newForm.InitTemperatureHeat - newForm.InitTemperatureHeatDelta;
      newForm.InitTemperatureColdBack =
        newForm.InitTemperatureCold + newForm.InitStationEvapTemperatureDelta;

      const weatherForm = {
        projectId: newProjectId,
        file: null,
        fileId: null,
        filename: null
      };

      const newStdForms = [];
      newForm.substations.forEach((sstForm) => {
        const newSstId = crypto.randomUUID();
        sstForm.AhsID = newProjectId;
        sstForm.InitStationID = newSstId;
        sstForm.ParentInitStationID = sstForm.InitStationID;
        sstForm.StdFileID = null;
        newStdForms.push({
          projectId: newProjectId,
          sstId: newSstId,
          file: null,
          fileId: null,
          filename: null,
          summaryData: null
        });
      });
      onFormsChange([...forms, newForm]);
      onWeatherFormsChange([...weatherForms, weatherForm]);
      onStdFormsChange([...stdForms, ...newStdForms]);
      setActiveTab(() => '' + formsCount);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleDeleteFormClick = async (projectId) => {
    try {
      await deleteProject(projectId);
      onStdFormsChange(stdForms.filter((f) => f.projectId !== projectId));
      onWeatherFormsChange(
        weatherForms.filter((f) => f.projectId !== projectId)
      );
      onFormsChange(forms.filter((f) => f.AhsID !== projectId));
      setActiveTab(DEFAULT_ACTIVE_TAB);
      openToast(
        i18n._('description.deleteDescription.success.title'),
        i18n._('description.deleteDescription.success.body'),
        'success'
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const noNeedSelected = (form) => {
    return (
      !form.InitStationHasHeatNeeds &&
      !form.InitStationHasColdNeeds &&
      !form.InitRefreshingEnabled &&
      !form.InitStationHasHotWaterNeeds
    );
  };

  const resetNeedsValues = (form) => {
    [
      ...Object.values(ENERGY_STORAGE),
      ...Object.values(GEOSTORAGE),
      ...Object.values(HOTWATER_PRODUCTION),
      ...Object.values(getHeatProductionParams()),
      ...Object.values(getColdProductionParams())
    ].forEach((param) => {
      form[param.key] = param.default;
    });
  };

  const noHotNeedSelected = (form) => {
    return !form.InitStationHasHeatNeeds && !form.InitStationHasHotWaterNeeds;
  };

  const resetGeostorageValues = (form) => {
    form.InitBtesEnabled = true;
    form.ItesEnabled = false;
    form.InitHPGHeatingEnabled = true;
    form.InitHPGCoolingEnabled = true;
    form.InitHPACoolingEnabled = false;
  };

  const handleInputChange = (key, value, formIndex) => {
    const newForms = cloneDeep(forms);
    if (typeof key === 'object') {
      key.forEach((k, i) => {
        newForms[formIndex][k] = value[i];
      });
    } else {
      newForms[formIndex][key] = value;
    }
    onFormsChange(newForms);
  };

  const handleHeatChange = (checked, formIndex) => {
    const newForms = cloneDeep(forms);
    if (!checked) {
      const heatProductionParams = getHeatProductionParams();
      Object.values(heatProductionParams).forEach((param) => {
        newForms[formIndex][param.key] = param.default;
      });
      if (noNeedSelected(newForms[formIndex])) {
        resetNeedsValues(newForms[formIndex]);
      }
    } else if (checked && noHotNeedSelected(newForms[formIndex])) {
      resetGeostorageValues(newForms[formIndex]);
    }
    newForms[formIndex].InitStationHasHeatNeeds = checked;
    onFormsChange(newForms);
  };

  const handleHotwaterChange = (checked, formIndex) => {
    const newForms = cloneDeep(forms);
    if (!checked) {
      Object.values(HOTWATER_PRODUCTION).forEach((param) => {
        newForms[formIndex][param.key] = param.default;
      });
      if (noNeedSelected(newForms[formIndex])) {
        resetNeedsValues(newForms[formIndex]);
      }
    } else if (checked && noHotNeedSelected(newForms[formIndex])) {
      resetGeostorageValues(newForms[formIndex]);
    }
    newForms[formIndex].InitStationHasHotWaterNeeds = checked;
    onFormsChange(newForms);
  };

  const handleColdChange = (checked, formIndex) => {
    const newForms = cloneDeep(forms);
    if (!checked) {
      const coldProductionParams = getColdProductionParams();
      Object.values(coldProductionParams).forEach((param) => {
        newForms[formIndex][param.key] = param.default;
      });
      if (noNeedSelected(newForms[formIndex])) {
        resetNeedsValues(newForms[formIndex]);
      }
    }
    newForms[formIndex].InitStationHasColdNeeds = checked;
    // Si on ajoute un besoin de climatisation, on supprime le besoin de rafraichissement
    if (checked) {
      newForms[formIndex].InitRefreshingEnabled = false;
    } else if (newForms[formIndex].InitRefreshingEnabled) {
      newForms[formIndex].InitColdRangeEnabled = false;
    }
    onFormsChange(newForms);
  };

  const handleRefreshingChange = (checked, formIndex) => {
    const newForms = cloneDeep(forms);
    if (!checked) {
      const coldProductionParams = getColdProductionParams();
      Object.values(coldProductionParams).forEach((param) => {
        newForms[formIndex][param.key] = param.default;
      });
      if (noNeedSelected(newForms[formIndex])) {
        resetNeedsValues(newForms[formIndex]);
      }
    }
    newForms[formIndex].InitRefreshingEnabled = checked;
    // Si on ajoute un besoin de rafrichissement, on ajoute le besoin de froid (utile pour le moteur, mais ne doit pas être visible dans le formulaire)
    if (checked) {
      newForms[formIndex].InitStationHasColdNeeds = true;
    } else if (newForms[formIndex].InitStationHasColdNeeds) {
      newForms[formIndex].InitStationHasColdNeeds = false;
      newForms[formIndex].InitColdRangeEnabled = false;
    }
    onFormsChange(newForms);
  };

  const handleNoStorageChange = (formIndex) => {
    const newForms = cloneDeep(forms);
    newForms[formIndex].InitBtesEnabled = false;
    newForms[formIndex].ItesEnabled = false;
    newForms[formIndex].InitHPGHeatingEnabled = false;
    newForms[formIndex].InitHPGCoolingEnabled = false;
    newForms[formIndex].InitHPACoolingEnabled =
      newForms[formIndex].InitStationHasColdNeeds;
    newForms[formIndex].InitGeocoolingEnabled = false;
    newForms[formIndex].InitColdRangeEnabled = false;
    onFormsChange(newForms);
  };

  const handleBtesChange = (formIndex) => {
    const newForms = cloneDeep(forms);
    newForms[formIndex].InitBtesEnabled = true;
    newForms[formIndex].ItesEnabled = false;
    newForms[formIndex].InitHPGHeatingEnabled = true;
    newForms[formIndex].InitHPGCoolingEnabled = true;
    newForms[formIndex].InitHPACoolingEnabled = false;
    newForms[formIndex].InitGeocoolingEnabled = false;
    newForms[formIndex].InitColdRangeEnabled = false;
    onFormsChange(newForms);
  };

  const handleItesChange = (formIndex) => {
    const newForms = cloneDeep(forms);
    newForms[formIndex].InitBtesEnabled = false;
    newForms[formIndex].ItesEnabled = true;
    newForms[formIndex].InitHPGHeatingEnabled = false;
    newForms[formIndex].InitHPGCoolingEnabled = false;
    newForms[formIndex].InitHPACoolingEnabled = true;
    newForms[formIndex].InitGeocoolingEnabled = false;
    onFormsChange(newForms);
  };

  const handleTemperatureHeatBackChange = (value, formIndex) => {
    const newForms = cloneDeep(forms);
    const delta = newForms[formIndex].InitTemperatureHeat - value;
    newForms[formIndex].InitTemperatureHeatDelta = delta;
    newForms[formIndex].InitTemperatureHeatBack = value;
    onFormsChange(newForms);
  };

  const handleGasReleveChange = (checked, formIndex) => {
    const newForms = cloneDeep(forms);
    newForms[formIndex].InitReleveEnabled = checked;
    newForms[formIndex].InitGasEnabled = checked;
    onFormsChange(newForms);
  };

  const handleGasAppointChange = (checked, formIndex) => {
    const newForms = cloneDeep(forms);
    newForms[formIndex].InitReleveEnabled = false;
    newForms[formIndex].InitGasEnabled = checked;
    onFormsChange(newForms);
  };

  const handleTemperatureColdBackChange = (value, formIndex) => {
    const newForms = cloneDeep(forms);
    const delta = value - newForms[formIndex].InitTemperatureCold;
    newForms[formIndex].InitStationEvapTemperatureDelta = delta;
    newForms[formIndex].InitTemperatureColdBack = value;
    onFormsChange(newForms);
  };

  const handleSstInputChange = (key, value, sstIndex) => {
    const newForms = cloneDeep(forms).map((form) => {
      form.substations[sstIndex][key] = value;
      return form;
    });
    onFormsChange(newForms);
  };

  const handleNewSstClick = () => {
    const newParentStationId = crypto.randomUUID();
    const newForms = cloneDeep(forms);
    const newStdForms = cloneDeep(stdForms);
    newForms.forEach((form, index) => {
      const newStationId = crypto.randomUUID();
      const newSst = {
        ...EMPTY_SUBSTATION,
        AhsID: form.AhsID,
        InitStationID: index === 0 ? newParentStationId : newStationId,
        ParentInitStationID: index === 0 ? null : newParentStationId,
        InitStationOrder:
          Math.max(...form.substations.map((sst) => sst.InitStationOrder)) + 1
      };
      form.substations.push(newSst);
      newStdForms.push({
        projectId: form.AhsID,
        sstId: index === 0 ? newParentStationId : newStationId,
        file: null,
        fileId: null,
        filename: null,
        summaryData: null
      });
    });
    onFormsChange(newForms);
    onStdFormsChange(newStdForms);
  };

  const handleStdFileChange = (evt, formIndex, sstIndex, stdForm) => {
    const csvReader = new FileReader();
    const file = evt.target.files[0];
    if (!file) return;

    csvReader.readAsText(file);
    csvReader.onload = (event) => {
      try {
        const buffer = event.target.result;
        const newForms = cloneDeep(forms);
        const newStdForms = cloneDeep(stdForms);
        const fileRows = bufferToArray(i18n, buffer, Object.values(STD_COLS));
        const summaryData = getSummaryData(fileRows);
        newForms.forEach((newForm) => {
          // on change la STD pour la sst courante + ses enfants dont la STD n'est pas définie si la sst courante est une sst parent
          newForm.substations.forEach((newSstForm, newSstIndex) => {
            if (
              newSstIndex === sstIndex &&
              (newSstForm.InitStationID === stdForm.sstId ||
                (formIndex === 0 && !newSstForm.StdFileID))
            ) {
              const fileId = crypto.randomUUID();
              const i = newStdForms.findIndex(
                (f) => f.sstId === newSstForm.InitStationID
              );
              newStdForms[i] = {
                ...newStdForms[i],
                file,
                fileId,
                filename: file.name,
                summaryData
              };
              newSstForm.StdFileID = fileId;
            }
          });
        });
        onFormsChange(newForms);
        onStdFormsChange(newStdForms);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      } finally {
        evt.target.value = null;
      }
    };
  };

  const handleSstDelete = async (sstForm, formIndex) => {
    try {
      const sstIdsToDelete = forms.map(
        (form) =>
          form.substations.find(
            (sst) => sst.InitStationOrder === sstForm.InitStationOrder
          ).InitStationID
      );
      await deleteSstParent(forms[formIndex].AhsID, sstForm.InitStationID);
      const newForms = cloneDeep(forms).map((oldForm) => {
        oldForm.substations = oldForm.substations.filter(
          (f) => !sstIdsToDelete.includes(f.InitStationID)
        );
        return { ...oldForm };
      });
      const newStdForms = cloneDeep(stdForms).filter(
        (f) => !sstIdsToDelete.includes(f.InitStationID)
      );
      onFormsChange(newForms);
      onStdFormsChange(newStdForms);
    } catch (err) {
      throw err;
    }
  };

  const handleWeatherFileChange = (evt, formIndex) => {
    const csvReader = new FileReader();
    const file = evt.target.files[0];
    if (!file) return;

    csvReader.readAsText(file);
    csvReader.onload = (evt) => {
      try {
        bufferToArray(i18n, evt.target.result, Object.values(WEATHER_COLS));
        const newFileId = crypto.randomUUID();
        const newForms = cloneDeep(forms);
        const newWeatherForms = cloneDeep(weatherForms);
        const weatherForm = weatherForms.find(
          (f) => f.projectId === forms[formIndex].AhsID
        );
        newForms[formIndex].WeatherFileID = newFileId;
        const newWeatherForm = {
          projectId: weatherForm.projectId,
          file,
          fileId: newFileId,
          filename: file.name
        };
        const i = newWeatherForms.findIndex(
          (f) => f.projectId === weatherForm.projectId
        );
        newWeatherForms[i] = newWeatherForm;
        onFormsChange(newForms);
        onWeatherFormsChange(newWeatherForms);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      } finally {
        evt.target.value = null;
      }
    };
  };
  //#endregion

  //#region [render]
  return (
    <div className='descriptions-tabs'>
      <Tab.Container
        defaultActiveKey={DEFAULT_ACTIVE_TAB}
        activeKey={activeTab}
        onSelect={(tab) => setActiveTab(tab)}
      >
        <Nav variant='tabs'>
          {forms.map((form, formIndex) => (
            <Nav.Item key={'desc_nav_item_' + formIndex}>
              <Nav.Link eventKey={'' + formIndex}>
                <span className='nav-link-span'>
                  {i18n._('description.descriptionTabLabel', {
                    id: formIndex + 1
                  })}
                  {formIndex > 0 && (
                    <FontAwesomeIcon
                      icon='trash-alt'
                      onClick={() =>
                        openConfirmModal(
                          i18n._('description.deleteDescription.title'),
                          i18n._('description.deleteDescription.body'),
                          'danger',
                          async () => await handleDeleteFormClick(form.AhsID)
                        )
                      }
                    />
                  )}
                </span>
              </Nav.Link>
            </Nav.Item>
          ))}
          {forms.length < MAX_DESCRIPTIONS && (
            <NavDropdown title={<FontAwesomeIcon icon='square-plus' />}>
              {forms.map((form, formIndex) => (
                <NavDropdown.Item
                  key={'duplicate_form_dropdown_' + formIndex}
                  onClick={async () => await handleDuplicateFormClick(form)}
                >
                  {i18n._('description.duplicateDescription', {
                    id: formIndex + 1
                  })}
                </NavDropdown.Item>
              ))}
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleNewFormClick}>
                {i18n._('description.createEmptyDescrption')}
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </Nav>
        <Tab.Content>
          {forms.map((form, formIndex) => (
            <Tab.Pane eventKey={formIndex} key={'tab_pane_form_' + formIndex}>
              <DescriptionTab
                form={form}
                formIndex={formIndex}
                weatherForm={weatherForms.find(
                  (f) => f.projectId === form.AhsID
                )}
                stdForms={stdForms}
                onHeatChange={(evt) =>
                  handleHeatChange(evt.target.checked, formIndex)
                }
                onHotwaterChange={(evt) =>
                  handleHotwaterChange(evt.target.checked, formIndex)
                }
                onColdChange={(evt) =>
                  handleColdChange(evt.target.checked, formIndex)
                }
                onRefreshingChange={(evt) =>
                  handleRefreshingChange(evt.target.checked, formIndex)
                }
                onNoStorageChange={() => handleNoStorageChange(formIndex)}
                onBtesChange={() => handleBtesChange(formIndex)}
                onItesChange={() => handleItesChange(formIndex)}
                onInputChange={(key, value) =>
                  handleInputChange(key, value, formIndex)
                }
                onTemperatureHeatBackChange={(value) =>
                  handleTemperatureHeatBackChange(value, formIndex)
                }
                onGasReleveChange={(checked) =>
                  handleGasReleveChange(checked, formIndex)
                }
                onGasAppointChange={(checked) =>
                  handleGasAppointChange(checked, formIndex)
                }
                onTemperatureColdBackChange={(value) =>
                  handleTemperatureColdBackChange(value, formIndex)
                }
                onSstInputChange={handleSstInputChange}
                onNewSstClick={handleNewSstClick}
                onStdFileChange={(evt, sstIndex, stdForm) =>
                  handleStdFileChange(evt, formIndex, sstIndex, stdForm)
                }
                onSstDelete={async (sstForm) =>
                  await handleSstDelete(sstForm, formIndex)
                }
                onWeatherFileChange={(evt) =>
                  handleWeatherFileChange(evt, formIndex)
                }
              />
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </div>
  );
  //#endregion
};

export default DescriptionsTabs;
