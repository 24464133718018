import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { Fragment } from 'react';
import { EQUIPMENT_FAMILY, UNIT } from '../../../../../../../server/constants';
import WarningBadge from '../../../../../components/WarningBadge';
import {
  getTableValue,
  isArrNullOrEmpty
} from '../../../../../utils/data.utils';
import SubEquipmentRow from './SubEquipmentRow';

const HeatpumpsRows = ({
  hpsList,
  subEquipementList,
  handleShowSubEquipment
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  return (
    <Fragment>
      {hpsList.map((hp, index) => {
        const lowerModuleRef = `${hp.position}_${hp?.lowerModule?.name}`;
        const upperModuleRef = `${hp.position}_${hp.upperModule.name}`;
        const visibleLowerModuleEquipment = hp.lowerModule?.equipment?.filter(
          (equipment) => equipment.family === EQUIPMENT_FAMILY.CLIMATIC
        );
        const hasSubEquipment = !isArrNullOrEmpty(visibleLowerModuleEquipment);
        const prices = hp?.lowerModule?.prices;
        return (
          <Fragment key={`${index}_${hp.model}_eq`}>
            {/* PAC */}
            <tr className={!!index ? 'equipment-list-separator' : ''}>
              <td></td>
              <td className='bold'>{hp.model}</td>

              <td colSpan={2} className='bold'>
                {getTableValue(i18n, hp.price, UNIT.EURO, 0)}
              </td>
              <td colSpan={5}></td>
              <td className='bold'></td>
            </tr>
            {/* Module bas de la PAC*/}
            {!hp.lowerModule ? (
              <tr>
                <td>
                  <WarningBadge
                    tooltipContent={i18n._(
                      'config.equipmentList.warning.noModule'
                    )}
                    tooltipId={'lowerModule' + '_' + hp.position}
                    portalContainer={document.body}
                    place='bottom'
                    iconSize='small'
                  />
                </td>
                <td colSpan={9} className='bold'>
                  {i18n._('config.hps.lowerModule.notFound')}
                </td>
              </tr>
            ) : (
              <tr>
                <td>
                  {hasSubEquipment ? (
                    <FontAwesomeIcon
                      className='equipment-list-chevron'
                      onClick={() => handleShowSubEquipment(lowerModuleRef)}
                      icon={
                        subEquipementList.includes(lowerModuleRef)
                          ? faChevronDown
                          : faChevronRight
                      }
                    />
                  ) : (
                    <WarningBadge
                      tooltipContent={i18n._(
                        'config.equipmentList.warning.noEquipment'
                      )}
                      tooltipId={'lowerModule' + '_' + hp.position}
                      portalContainer={document.body}
                      place='bottom'
                      iconSize='small'
                    />
                  )}
                </td>
                <td className='bold'>{hp.lowerModule.name}</td>
                <td colSpan={2} className='bold'>
                  {getTableValue(
                    i18n,
                    prices.moduleEquipmentTotal,
                    UNIT.EURO,
                    0
                  )}
                </td>
                <td className='bold'>
                  {getTableValue(i18n, prices.manufacturingTotal, UNIT.EURO, 0)}
                </td>
                <td>
                  {getTableValue(
                    i18n,
                    prices.hydraulicAndStructure,
                    UNIT.EURO,
                    0
                  )}
                </td>
                <td>
                  {getTableValue(i18n, prices.factoryInsulation, UNIT.EURO, 0)}
                </td>
                <td>
                  {getTableValue(i18n, prices.siteInsulation, UNIT.EURO, 0)}
                </td>
                <td>
                  {getTableValue(i18n, prices.equipmentConfig, UNIT.EURO, 0)}
                </td>
                <td className='bold'>
                  {getTableValue(i18n, prices.total, UNIT.EURO, 0)}
                </td>
              </tr>
            )}
            {subEquipementList.includes(lowerModuleRef) && (
              <SubEquipmentRow list={visibleLowerModuleEquipment} />
            )}
            {/* Module haut de la PAC */}
            <tr>
              <td>
                <FontAwesomeIcon
                  className='equipment-list-chevron'
                  onClick={() => handleShowSubEquipment(upperModuleRef)}
                  icon={
                    subEquipementList.includes(upperModuleRef)
                      ? faChevronDown
                      : faChevronRight
                  }
                />
              </td>
              <td className='bold'>{hp.upperModule.name}</td>
              <td colSpan={2} className='bold'>
                {getTableValue(
                  i18n,
                  hp.upperModule.prices.moduleEquipmentTotal,
                  UNIT.EURO,
                  0
                )}
              </td>
              <td className='bold'>
                {getTableValue(
                  i18n,
                  hp.upperModule.prices.manufacturingTotal,
                  UNIT.EURO,
                  0
                )}
              </td>
              <td>
                {getTableValue(
                  i18n,
                  hp.upperModule.prices.hydraulicAndStructure,
                  UNIT.EURO,
                  0
                )}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td className='bold'>
                {getTableValue(i18n, hp.upperModule.prices.total, UNIT.EURO, 0)}
              </td>
            </tr>
            {subEquipementList.includes(upperModuleRef) && (
              <SubEquipmentRow list={hp.upperModule.equipment} />
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default HeatpumpsRows;
