// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hps-selection-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  font-size: 15px;
  margin-bottom: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.hps-selection-btn svg {
  font-size: 18px;
}

.hps-selection-tables {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 25px;
}

.hps-selection-warning {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 14px;
}

.gap-cell {
  font-weight: bold;
  border-bottom: 1px solid black;
}

.gap-base-cell {
  background-color: #dee2e6;
  color: black;
}

.gap-danger-cell {
  background-color: #e8d2d6;
  color: var(--danger-color);
}

.gap-warning-cell {
  background-color: #FFF3CD;
  color: #664D03;
}

.gap-success-cell {
  background-color: #d5e7d4;
  color: var(--success-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configs/thermalProduction/heatpumps/selection/HpsSelectionPage.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,yBAAyB;MACrB,sBAAsB;UAClB,mBAAmB;EAC3B,QAAQ;EACR,eAAe;EACf,mBAAmB;EACnB,0BAA0B;EAC1B,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,4BAA4B;EAC5B,6BAA6B;MACzB,0BAA0B;UACtB,sBAAsB;EAC9B,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;AAC7B","sourcesContent":[".hps-selection-btn {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 8px;\n  font-size: 15px;\n  margin-bottom: 10px;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n\n.hps-selection-btn svg {\n  font-size: 18px;\n}\n\n.hps-selection-tables {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  gap: 25px;\n}\n\n.hps-selection-warning {\n  margin-top: 20px;\n  margin-bottom: 0;\n  font-size: 14px;\n}\n\n.gap-cell {\n  font-weight: bold;\n  border-bottom: 1px solid black;\n}\n\n.gap-base-cell {\n  background-color: #dee2e6;\n  color: black;\n}\n\n.gap-danger-cell {\n  background-color: #e8d2d6;\n  color: var(--danger-color);\n}\n\n.gap-warning-cell {\n  background-color: #FFF3CD;\n  color: #664D03;\n}\n\n.gap-success-cell {\n  background-color: #d5e7d4;\n  color: var(--success-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
