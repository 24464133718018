import axios from '../conf/axios.conf';

//#region [GET]
export const fetchProjectParent = async (projectParentId) => {
  try {
    const response = await axios.get(`/project/${projectParentId}?parent=true`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchResults = async (projectParentId) => {
  try {
    const response = await axios.get(`/project/${projectParentId}/results`);
    const groups = response.data;
    groups.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    groups.forEach((group) => {
      group.results.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    });
    return groups;
  } catch (err) {
    throw err;
  }
};

export const fetchResultsForNextCompute = async (
  projectParentId,
  computeType
) => {
  try {
    const url = `/project/${projectParentId}/resultsForNextCompute?type=${computeType}`;
    const response = await axios.get(url);
    const groups = response.data;
    groups.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    groups.forEach((group) => {
      group.results.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    });
    return groups;
  } catch (err) {
    throw err;
  }
};

export const fetchCurrentCalculation = async (projectParentId) => {
  try {
    const url = `/project/${projectParentId}/calculation`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchComputeIdByTag = async (projectParentId, tag) => {
  try {
    const response = await axios.get(`/project/${projectParentId}/tag/${tag}`);
    return response.data.computeId;
  } catch (err) {
    throw err;
  }
};

export const fetchConfigIdByTag = async (projectParentId, tag) => {
    try {
      const response = await axios.get(`/project/${projectParentId}/config-tag/${tag}`);
      return response.data.configId;
    } catch (err) {
      throw err;
    }
  };

export const fetchLastResultId = async (projectParentId) => {
  try {
    const response = await axios.get(
      `/project/${projectParentId}/lastResultId`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchLastCalculationError = async (projectParentId) => {
  try {
    const response = await axios.get(
      `/project/${projectParentId}/lastCalculationError`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchGeneral = async (projectParentId) => {
  try {
    const response = await axios.get(`/project/${projectParentId}/general`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchGeology = async (projectParentId) => {
  try {
    const response = await axios.get(`/project/${projectParentId}/geology`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchDescriptions = async (projectParentId) => {
  try {
    const response = await axios.get(
      `/project/${projectParentId}/descriptions`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchCost = async (projectParentId) => {
  try {
    const response = await axios.get(`/project/${projectParentId}/cost`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchPerformance = async (projectParentId) => {
  try {
    const response = await axios.get(`/project/${projectParentId}/performance`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchProjectLocation = async (projectId) => {
  try {
    const response = await axios.get(`/project/${projectId}/location`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchConfigs = async (projectId) => {
  try {
    const response = await axios.get(`/project/${projectId}/configs`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [POST]
export const createProjectParent = async (formData) => {
  try {
    const response = await axios.post('/project/newParent', formData);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const startCalculation = async (projectParentId, calculationData) => {
  try {
    const url = `/project/${projectParentId}/startCalculation`;
    const response = await axios.post(url, calculationData);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const cancelCalculation = async (projectParentId) => {
  try {
    const url = `/project/${projectParentId}/cancelCalculation`;
    const response = await axios.post(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const createConfig = async (projectId) => {
  try {
    const url = `/project/${projectId}/config`;
    const response = await axios.post(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [PUT]
export const updateGeneral = async (projectParentId, formData) => {
  try {
    const url = `/project/${projectParentId}/general`;
    const response = await axios.put(url, formData);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateDescriptions = async (projectParentId, formData) => {
  try {
    const url = `/project/${projectParentId}/descriptions`;
    const response = await axios.put(url, formData);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateGeology = async (projectParentId, params) => {
  try {
    const url = `/project/${projectParentId}/geology`;
    await axios.put(url, params);
  } catch (err) {
    throw err;
  }
};

export const updateCost = async (projectParentId, params) => {
  try {
    const url = `/project/${projectParentId}/cost`;
    await axios.put(url, params);
  } catch (err) {
    throw err;
  }
};

export const updatePerformance = async (projectParentId, params) => {
  try {
    const url = `/project/${projectParentId}/performance`;
    await axios.put(url, params);
  } catch (err) {
    throw err;
  }
};

export const updateInputs = async (projectParentId, computeId) => {
  try {
    const url = `/project/${projectParentId}/computeInputs/${computeId}`;
    await axios.put(url);
  } catch (err) {
    throw err;
  }
};

export const restoreProject = async (projectId) => {
  try {
    await axios.put(`/project/${projectId}/restore`);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [DELETE]
export const deleteProject = async (projectId) => {
  try {
    await axios.delete(`/project/${projectId}`);
  } catch (err) {
    throw err;
  }
};

export const deleteProjectParent = async (projectId) => {
  try {
    await axios.delete(`/project/${projectId}?parent=true`);
  } catch (err) {
    throw err;
  }
};

export const deleteSstParent = async (projectId, sstId) => {
  try {
    await axios.delete(`/project/${projectId}/sstParent/${sstId}`);
  } catch (err) {
    throw err;
  }
};
//#endregion
