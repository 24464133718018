import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { UNIT } from '../../../../../../../../server/constants';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import {
  formatValueWithUnit,
  isNull,
  roundUp
} from '../../../../../../utils/data.utils';
import { getSortedExchangers } from '../../../../../../utils/heatpump.utils';

const HeadLossTotalSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [methods]
  const formatValue = (value, unit, precision) =>
    !isNull(value)
      ? formatValueWithUnit(i18n, value, unit, precision)
      : i18n._('notAvailable');
  //#endregion

  //#region [render]
  const { exchangersHeadLossData } = config.ConfigsSst[0].Data.pumps;
  const sortedExchangers = getSortedExchangers(exchangersHeadLossData);
  return (
    <Section
      title={i18n._('config.pumps.headLoss.flowRateHeadLossPair')}
      level={2}
      open
    >
      <table className='custom-table'>
        <thead>
          <tr>
            <th>{i18n._('config.pumps.headLoss.pumps')}</th>
            <th>{i18n._('config.pumps.headLoss.hp')}</th>
            <th>{i18n._('config.pumps.headLoss.need')}</th>
            <th>{i18n._('config.pumps.headLoss.flowRate')}</th>
            <th>{i18n._('config.pumps.headLoss.total')}</th>
          </tr>
        </thead>
        <tbody>
          {sortedExchangers.map((exchanger, index) => {
            const headLossTotal =
              (exchanger.hpHeadLoss ?? 0) +
              (exchanger.pipingHeadLoss.linearHeadLoss ?? 0) +
              (exchanger.pipingHeadLoss.singularHeadLoss ?? 0) +
              (exchanger.pipingHeadLoss.hpaExchangerHeadLoss ?? 0) +
              (exchanger.collectorsHeadLoss?.linearHeadLoss ?? 0) +
              (exchanger.collectorsHeadLoss?.singularHeadLoss ?? 0) +
              (exchanger.destinationsHeadLoss ?? 0);
            return (
              <tr key={'exchanger_destinations_headloss' + index}>
                <td>
                  {i18n._(
                    `hydraulicConfig.table.td.${exchanger.exchangerType}`,
                    {
                      position: exchanger.hpPosition
                    }
                  )}
                </td>
                <td>{exchanger.hpModel}</td>
                <td>{i18n._(`need.${exchanger.hpNeed}`)}</td>
                <td className='col-flowRate'>
                  {formatValue(
                    exchanger.exchangerFlowRate,
                    UNIT.CUBIC_METER_PER_HOUR,
                    1
                  )}
                </td>
                <td className='col-headLoss'>
                  {formatValue(
                    roundUp(headLossTotal, 1),
                    UNIT.WATER_COLUMN_METER,
                    1
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Section>
  );
  //#endregion
};

export default HeadLossTotalSection;
