import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import {
  MODULES,
  MODULES_WARNINGS
} from '../../../../../../../../server/constants';
import Section from '../../../../../../components/Section/Section';
import WarningBadge from '../../../../../../components/WarningBadge';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import { isOutOfRange } from '../../../../../../utils/data.utils';

const ModulesSelection = ({ moduleType }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //endregion

  //#region [render]
  const { services, geostorage } = config.ConfigsSst[0].Data;
  const isInjectionPriceComplete =
    !isOutOfRange(
      services.upperModules.condensor.nominalDiameter,
      MODULES_WARNINGS.UPPER_MODULES_INJECTION_ND_RANGE
    ) &&
    !isOutOfRange(
      services.upperModules.evaporator.nominalDiameter,
      MODULES_WARNINGS.UPPER_MODULES_INJECTION_ND_RANGE
    );
  return (
    <Section
      title={i18n._(`hydraulicConfig.${moduleType}.selection`)}
      level={2}
      open
    >
      <table className='custom-table'>
        <thead>
          <tr>
            <th></th>
            <th>{i18n._('geostorage.hydraulic.module')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{i18n._('src.exchange.capture')}</td>
            {moduleType === MODULES.UPPER_MODULES ? (
              <td>-</td>
            ) : (
              <td>
                {geostorage?.[moduleType]?.capture?.name ?? i18n._('noValue')}
              </td>
            )}
          </tr>
          <tr>
            <td>{i18n._('src.exchange.injection')}</td>
            <td>
              <span className='injection-nominalDiameter-warning'>
                {geostorage?.[moduleType]?.injection?.name ?? i18n._('noValue')}
                {moduleType === MODULES.UPPER_MODULES &&
                  !isInjectionPriceComplete && (
                    <WarningBadge
                      tooltipContent={i18n._(
                        'config.equipmentList.warning.incompletePrice'
                      )}
                      tooltipId={'injectionUpperModule'}
                      portalContainer={document.body}
                      place='bottom'
                      iconSize='medium'
                    />
                  )}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </Section>
  );
  //endregion
};
export default ModulesSelection;
