import { BroadcastChannel } from 'broadcast-channel';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchConfig } from '../../api/config.api';
import { fetchModules } from '../../api/modules.api';
import ConfigsContext from '../../contexts/ConfigsContext';
import PopupContext from '../../contexts/PopupContext';
import { isArrNullOrEmpty } from '../../utils/data.utils';
import './ConfigsPage.css';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';

const ConfigsPage = () => {
  //#region [router]
  const navigate = useNavigate();
  const { configId } = useParams();
  const location = useLocation();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [isLoading, setIsLoading] = useState();
  const [config, setConfig] = useState();
  const [modules, setModules] = useState();
  //#endregion

  //#region [refs]
  const channel = useRef();
  const savedConfig = useRef();
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!configId) return;
    (async () => {
      if (channel.current) {
        await channel.current.close();
      }
      channel.current = new BroadcastChannel(configId);
    })();

    return async () => {
      await channel.current?.close();
    };
  }, [configId]);

  useEffect(() => {
    if (!savedConfig?.current) return;
    const configMsg = JSON.stringify({
      type: 'scope'
    });
    if (!channel.current.onmessage) {
      channel.current.onmessage = (msg) => {
        msg = JSON.parse(msg);
        switch (msg.type) {
          case 'connection':
            channel.current.postMessage(configMsg);
            break;
          default:
            console.warn('unknown broadcast channel msg', msg.type);
        }
      };
    }
    channel.current.postMessage(configMsg);
  }, [savedConfig?.current]);

  useEffect(() => {
    if (
      !location?.pathname ||
      !location.pathname.includes('configs') ||
      config?.ConfigID === configId ||
      !configId
    ) {
      if (!configId && savedConfig.current) {
        setConfig(null);
        savedConfig.current = null;
      }
      return;
    }

    (async () => {
      try {
        setIsLoading(() => true);
        if (config) await channel.current?.close();
        const conf = location?.state?.config ?? (await fetchConfig(configId));
        setConfig(() => cloneDeep(conf));
        savedConfig.current = cloneDeep(conf);
      } catch (err) {
        console.error(err);
        if (err.response?.status === 404) {
          navigate('/not-found');
        } else {
          openErrorToast(err);
        }
      } finally {
        setIsLoading(() => false);
      }
    })();
  }, [configId]);

  useEffect(() => {
    (async () => {
      try {
        if (config && isArrNullOrEmpty(modules)) {
          const configSstId = config?.ConfigsSst[0]?.ConfigSstID;
          const modules = await fetchModules(configSstId);
          setModules(() => modules);
        }
      } catch (err) {
        throw err;
      }
    })();
  }, [config]);
  //#endregion

  //#region [methods]
  const refreshConfig = async () => {
    try {
      const conf = await fetchConfig(config.ConfigID);
      setConfig(() => cloneDeep(conf));
      savedConfig.current = cloneDeep(conf);
    } catch (err) {
      console.error(err);
      if (err.response?.status === 404) {
        navigate('/not-found');
      } else {
        openErrorToast(err);
      }
    }
  };

  const refreshConfigTags = async () => {
    try {
      // recharge de la configuration pour récupérer les tags
      const conf = await fetchConfig(config.ConfigID);
      config.Tags = conf.Tags;
      // mise à jour du state
      setConfig(cloneDeep(config));
      savedConfig.current = cloneDeep(config);
    } catch (err) {
      console.error(err);
      if (err.response?.status === 404) {
        navigate('/not-found');
      } else {
        openErrorToast(err);
      }
    }
  };

  //#endregion

  //#region [render]
  if (config?.IsModular && !modules) return;
  return (
    <div className='configs-page'>
      <Header savedConfig={savedConfig.current} />
      {config ? (
        <ConfigsContext.Provider
          value={{
            config,
            savedConfig: savedConfig.current,
            setConfig,
            refreshConfig,
            refreshConfigTags,
            modules
          }}
        >
          <div className='configs-page-body'>
            <Sidebar />
            {isLoading ? (
              <div className='configs-page-spinner-wrapper'>
                <Spinner variant='dark' />
              </div>
            ) : (
              <Outlet />
            )}
          </div>
        </ConfigsContext.Provider>
      ) : null}
    </div>
  );
  //#endregion
};

export default ConfigsPage;
