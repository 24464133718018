import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTemperatureArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Alert, Form } from 'react-bootstrap';
import Bloc from '../../../../../components/Bloc/Bloc';
import './BuildingNeedsBloc.css';
import { Tooltip } from 'react-tooltip';

const helpUrl = 'https://www.notion.so/accenta/Besoins-du-b-timent-05474420b409414bbf3cc08049ab66bd#0b5e300eda8d4930a63e9303c69d2683';

const HelpComponent = ({ helpUrl }) => {
  return (
    <span className='help-component'>
      <a href={helpUrl} target='_blank' rel='noreferrer'>
        <FontAwesomeIcon icon='question-circle' />
      </a>
    </span>
  );
};

const BuildingNeedsBloc = ({
  form,
  formIndex,
  onHeatChange,
  onHotwaterChange,
  onColdChange,
  onRefreshingChange
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  const noNeedsError =
    !form.InitStationHasHeatNeeds &&
    !form.InitStationHasColdNeeds &&
    !form.InitStationHasHotWaterNeeds &&
    !form.InitRefreshingEnabled;
  // on ajoute une largeur dynamique à chaque Form.Check pour que les labels ne bougent pas quand on sélectionne un besoin
  return (
    <Bloc title={i18n._('description.projectNeeds')} help={<HelpComponent helpUrl={helpUrl} />}>
      <div className='building-needs-row'>
        <div
          className={
            'building-need-wrapper' +
            (form.InitStationHasHeatNeeds ? ' checked' : '')
          }
        >
          <Form.Check
            type='checkbox'
            label={
              <>
                {i18n._('description.InitStationHasHeatNeeds')}
                <FontAwesomeIcon icon='sun' />
              </>
            }
            checked={form.InitStationHasHeatNeeds}
            onChange={onHeatChange}
            name='InitStationHasHeatNeeds'
            id={'InitStationHasHeatNeeds_' + formIndex}
          />
        </div>
        <div
          className={
            'building-need-wrapper' +
            (form.InitStationHasHotWaterNeeds ? ' checked' : '')
          }
        >
          <Form.Check
            type='checkbox'
            label={
              <>
                {i18n._('description.InitStationHasHotWaterNeeds')}
                <FontAwesomeIcon icon='water' />
              </>
            }
            checked={form.InitStationHasHotWaterNeeds}
            onChange={onHotwaterChange}
            name='InitStationHasHotWaterNeeds'
            id={'InitStationHasHotWaterNeeds_' + formIndex}
          />
        </div>
        <div
          className={
            'building-need-wrapper' +
            (form.InitStationHasColdNeeds && !form.InitRefreshingEnabled ? ' checked' : '')
          }
          data-tooltip-id='cold_needs_tooltip'
          data-tooltip-content={i18n._('description.ColdNeedsTooltip')}
        >
          <Form.Check
            type='checkbox'
            label={
              <>
                {i18n._('description.InitStationHasColdNeeds')}
                <FontAwesomeIcon icon='snowflake' />
              </>
            }
            checked={form.InitStationHasColdNeeds && !form.InitRefreshingEnabled}
            onChange={onColdChange}
            name='InitStationHasColdNeeds'
            id={'InitStationHasColdNeeds_' + formIndex}
          />
          <Tooltip
            id='cold_needs_tooltip'
            place='bottom'
            className='info-tooltip'
            opacity={1}
          />
        </div>
        <div
          className={
            'building-need-wrapper' +
            (form.InitRefreshingEnabled ? ' checked' : '')
          }
          data-tooltip-id='refreshing_needs_tooltip'
          data-tooltip-content={i18n._('description.RefreshingNeedsTooltip')}
        >
          <Form.Check
            type='checkbox'
            label={
              <>
                {i18n._('description.InitRefreshingEnabled')}
                <FontAwesomeIcon icon={faTemperatureArrowDown} />
              </>
            }
            checked={form.InitRefreshingEnabled}
            onChange={onRefreshingChange}
            name='InitRefreshingEnabled'
            id={'InitRefreshingEnabled_' + formIndex}
          />
          <Tooltip
            id='refreshing_needs_tooltip'
            place='bottom'
            className='info-tooltip'
            opacity={1}
          />
        </div>
      </div>
      {noNeedsError && (
        <Alert variant='danger'>{i18n._('description.error.noNeeds')}</Alert>
      )}
    </Bloc>
  );
  //#endregion
};

export default React.memo(BuildingNeedsBloc);
