import { isNull } from './data.utils';

export const getInputError = (i18n, value, param) => {
  // le champ est vide et non-obligatoire : pas d'erreur
  if (
    !param.required &&
    (isNull(value) || value.toString().trim().length === 0)
  )
    return null;

  let error;
  if (
    param.required &&
    (isNull(value) || value.toString().trim().length === 0)
  ) {
    // le param est obligatoire mais la valeur est manquante
    error = i18n._('input.error.required');
  } else if (param.type === 'number') {
    // le param est un nombre
    if (isNaN(value)) {
      error = i18n._('input.error.notNumber');
    } else if (param.min !== null && +value < param.min) {
      error = i18n._('input.error.min', { min: param.min });
    } else if (param.max !== null && +value > param.max) {
      error = i18n._('input.error.max', { max: param.max });
    } else if (param.exclusiveMin !== null && +value <= param.exclusiveMin) {
      error = i18n._('input.error.exclusiveMin', { min: param.exclusiveMin });
    } else if (param.exclusiveMax !== null && +value >= param.exclusiveMax) {
      error = i18n._('input.error.exclusiveMax', { max: param.exclusiveMax });
    }
  } else if (param.type === 'string' || param.type === 'password') {
    value = value.toString();
    if (param.minLength && value.length < param.minLength) {
      error = i18n._('input.error.minLength', { min: param.minLength });
    } else if (param.maxLength && value.length > param.maxLength) {
      error = i18n._('input.error.maxLength', { max: param.maxLength });
    } else if (param.pattern && !param.pattern.test(value)) {
      error = i18n._('input.error.pattern');
    }
  }
  return error;
};

const getEngineErrors = (i18n, customMessage = null) => [
  {
    code: 'unknown',
    message: i18n._('calculation.error.unknown.message'),
    causes: [
      {
        cause: customMessage || 'Unknown error'
      }
    ]
  },
  {
    code: 'redis_unavailable',
    causes: [
      {
        cause: i18n._('calculation.error.redis_unavailable.cause1'),
        actions: [
          i18n._('calculation.error.redis_unavailable.cause1.action1'),
          i18n._('calculation.error.redis_unavailable.cause1.action2')
        ]
      }
    ]
  },
  {
    code: 'end_all_crash',
    message: i18n._('calculation.error.end_all_crash.message'),
    causes: [
      {
        cause: i18n._('calculation.error.end_all_crash.cause1'),
        actions: [
          i18n._('calculation.error.end_all_crash.cause1.action1'),
          i18n._('calculation.error.end_all_crash.cause1.action2')
        ]
      },
      {
        cause: i18n._('calculation.error.end_all_crash.cause2'),
        actions: [
          i18n._('calculation.error.end_all_crash.cause2.action1'),
          i18n._('calculation.error.end_all_crash.cause2.action2')
        ]
      },
      {
        cause: i18n._('calculation.error.end_all_crash.cause3'),
        actions: [i18n._('calculation.error.end_all_crash.cause3.action1')]
      }
    ]
  },
  {
    code: 'end_allreject',
    message: i18n._('calculation.error.end_allreject.message'),
    causes: [
      {
        cause: i18n._('calculation.error.end_allreject.cause1'),
        actions: [
          i18n._('calculation.error.end_allreject.cause1.action1'),
          i18n._('calculation.error.end_allreject.cause1.action2'),
          i18n._('calculation.error.end_allreject.cause1.action3')
        ]
      }
    ]
  },
  {
    code: 'pre_btes_too_small',
    causes: [
      {
        cause: i18n._('calculation.error.pre_btes_too_small.cause1'),
        actions: [i18n._('calculation.error.pre_btes_too_small.cause1.action1')]
      },
      {
        cause: i18n._('calculation.error.pre_btes_too_small.cause2'),
        actions: [
          i18n._('calculation.error.pre_btes_too_small.cause2.action1'),
          i18n._('calculation.error.pre_btes_too_small.cause2.action2')
        ]
      }
    ]
  },
  {
    code: 'pre_hybridpv_capex',
    causes: [
      {
        cause: i18n._('calculation.error.pre_hybridpv_capex.cause1'),
        actions: [i18n._('calculation.error.pre_hybridpv_capex.cause1.action1')]
      }
    ]
  },
  {
    code: 'pre_no_btes_length',
    causes: [
      {
        cause: i18n._('calculation.error.pre_no_btes_length.cause1'),
        actions: [i18n._('calculation.error.pre_no_btes_length.cause1.action1')]
      }
    ]
  },
  {
    code: 'pre_no_cold_in_std',
    causes: [
      {
        cause: i18n._('calculation.error.pre_no_cold_in_std.cause1'),
        actions: [
          i18n._('calculation.error.pre_no_cold_in_std.cause1.action1'),
          i18n._('calculation.error.pre_no_cold_in_std.cause1.action2')
        ]
      }
    ]
  },
  {
    code: 'pre_no_dhw_in_std',
    causes: [
      {
        cause: i18n._('calculation.error.pre_no_dhw_in_std.cause1'),
        actions: [
          i18n._('calculation.error.pre_no_dhw_in_std.cause1.action1'),
          i18n._('calculation.error.pre_no_dhw_in_std.cause1.action2')
        ]
      }
    ]
  },
  {
    code: 'pre_no_hot_in_std',
    causes: [
      {
        cause: i18n._('calculation.error.pre_no_hot_in_std.cause1'),
        actions: [
          i18n._('calculation.error.pre_no_hot_in_std.cause1.action1'),
          i18n._('calculation.error.pre_no_hot_in_std.cause1.action2')
        ]
      }
    ]
  },
  {
    code: 'pre_no_hpa_power',
    causes: [
      {
        cause: i18n._('calculation.error.pre_no_hpa_power.cause1'),
        actions: [
          i18n._('calculation.error.pre_no_hpa_power.cause1.action1'),
          i18n._('calculation.error.pre_no_hpa_power.cause1.action2')
        ]
      }
    ]
  },
  {
    code: 'pre_no_hpg_power',
    causes: [
      {
        cause: i18n._('calculation.error.pre_no_hpg_power.cause1'),
        actions: [i18n._('calculation.error.pre_no_hpg_power.cause1.action1')]
      }
    ]
  },
  {
    code: 'pre_no_sst_hpg_power',
    causes: [
      {
        cause: i18n._('calculation.error.pre_no_sst_hpg_power.cause1'),
        actions: [
          i18n._('calculation.error.pre_no_sst_hpg_power.cause1.action1')
        ]
      }
    ]
  },
  {
    code: 'pre_btes_dist_depth_mismatch',
    causes: [
      {
        cause: i18n._('calculation.error.pre_btes_dist_depth_mismatch.cause1'),
        actions: [
          i18n._(
            'calculation.error.pre_btes_dist_depth_mismatch.cause1.action1'
          ),
          i18n._(
            'calculation.error.pre_btes_dist_depth_mismatch.cause1.action2'
          )
        ]
      }
    ]
  },
  {
    code: 'cop_cold_hpg_panic',
    causes: [
      {
        cause: i18n._('calculation.error.cop_cold_hpg_panic.cause1')
      }
    ]
  },
  {
    code: 'cop_heat_hpg_panic',
    causes: [
      {
        cause: i18n._('calculation.error.cop_heat_hpg_panic.cause1'),
        actions: [i18n._('calculation.error.cop_heat_hpg_panic.cause1.action1')]
      }
    ]
  },
  {
    code: 'cop_heat_st_panic',
    causes: [
      {
        cause: i18n._('calculation.error.cop_heat_st_panic.cause1'),
        actions: [
          i18n._('calculation.error.cop_heat_st_panic.cause1.action1'),
          i18n._('calculation.error.cop_heat_st_panic.cause1.action2')
        ]
      }
    ]
  },
  {
    code: 'cop_hwt_hpg_panic',
    causes: [
      {
        cause: i18n._('calculation.error.cop_hwt_hpg_panic.cause1'),
        actions: [i18n._('calculation.error.cop_hwt_hpg_panic.cause1.action1')]
      }
    ]
  },
  {
    code: 'cop_hwt_st_panic',
    causes: [
      {
        cause: i18n._('calculation.error.cop_hwt_st_panic.cause1'),
        actions: [
          i18n._('calculation.error.cop_hwt_st_panic.cause1.action1'),
          i18n._('calculation.error.cop_hwt_st_panic.cause1.action2')
        ]
      }
    ]
  },
  {
    code: 'pre_btes_dist_default',
    causes: [
      {
        cause: i18n._('calculation.error.pre_btes_dist_default.cause1'),
        actions: [
          i18n._('calculation.error.pre_btes_dist_default.cause1.action1'),
          i18n._('calculation.error.pre_btes_dist_default.cause1.action2')
        ]
      }
    ]
  },
  {
    code: 'pre_btes_dist_depth_lowerbound',
    causes: [
      {
        cause: i18n._(
          'calculation.error.pre_btes_dist_depth_lowerbound.cause1'
        ),
        actions: [
          i18n._(
            'calculation.error.pre_btes_dist_depth_lowerbound.cause1.action1'
          ),
          i18n._(
            'calculation.error.pre_btes_dist_depth_lowerbound.cause1.action2'
          )
        ]
      }
    ]
  },
  {
    code: 'pre_btes_dist_depth_unknown',
    causes: [
      {
        cause: i18n._('calculation.error.pre_btes_dist_depth_unknown.cause1'),
        actions: [
          i18n._(
            'calculation.error.pre_btes_dist_depth_unknown.cause1.action1'
          ),
          i18n._('calculation.error.pre_btes_dist_depth_unknown.cause1.action2')
        ]
      }
    ]
  },
  {
    code: 'pre_btes_dist_depth_upperbound',
    causes: [
      {
        cause: i18n._(
          'calculation.error.pre_btes_dist_depth_upperbound.cause1'
        ),
        actions: [
          i18n._(
            'calculation.error.pre_btes_dist_depth_upperbound.cause1.action1'
          ),
          i18n._(
            'calculation.error.pre_btes_dist_depth_upperbound.cause1.action2'
          )
        ]
      }
    ]
  },
  {
    code: 'pre_btes_length_unknown',
    causes: [
      {
        cause: i18n._('calculation.error.pre_btes_length_unknown.cause1'),
        actions: [
          i18n._('calculation.error.pre_btes_length_unknown.cause1.action1')
        ]
      }
    ]
  },
  {
    code: 'pre_geocooling_temp',
    causes: [
      {
        cause: i18n._('calculation.error.pre_geocooling_temp.cause1'),
        actions: [
          i18n._('calculation.error.pre_geocooling_temp.cause1.action1'),
          i18n._('calculation.error.pre_geocooling_temp.cause1.action2'),
          i18n._('calculation.error.pre_geocooling_temp.cause1.action3')
        ]
      }
    ]
  },
  {
    code: 'pre_heat_temperature',
    causes: [
      {
        cause: i18n._('calculation.error.pre_heat_temperature.cause1'),
        actions: [
          i18n._('calculation.error.pre_heat_temperature.cause1.action1'),
          i18n._('calculation.error.pre_heat_temperature.cause1.action2'),
          i18n._('calculation.error.pre_heat_temperature.cause1.action3')
        ]
      }
    ]
  },
  {
    code: 'pre_hotwater_temperature',
    causes: [
      {
        cause: i18n._('calculation.error.pre_hotwater_temperature.cause1'),
        actions: [
          i18n._('calculation.error.pre_hotwater_temperature.cause1.action1'),
          i18n._('calculation.error.pre_hotwater_temperature.cause1.action2'),
          i18n._('calculation.error.pre_hotwater_temperature.cause1.action3'),
          i18n._('calculation.error.pre_hotwater_temperature.cause1.action4')
        ]
      }
    ]
  },
  {
    code: 'pre_no_borehole',
    causes: [
      {
        cause: i18n._('calculation.error.pre_no_borehole.cause1'),
        actions: [i18n._('calculation.error.pre_no_borehole.cause1.action1')]
      }
    ]
  },
  {
    code: 'pre_no_hpa_heating',
    causes: [
      {
        cause: i18n._('calculation.error.pre_no_hpa_heating.cause1'),
        actions: [
          i18n._('calculation.error.pre_no_hpa_heating.cause1.action1'),
          i18n._('calculation.error.pre_no_hpa_heating.cause1.action2')
        ]
      }
    ]
  },
  {
    code: 'pre_params_bounds_too_large',
    causes: [
      {
        cause: i18n._('calculation.error.pre_params_bounds_too_large.cause1'),
        actions: [
          i18n._('calculation.error.pre_params_bounds_too_large.cause1.action1')
        ]
      }
    ]
  },
  {
    code: 'qbtes_panic',
    causes: [
      {
        cause: i18n._('calculation.error.qbtes_panic.cause1'),
        actions: [i18n._('calculation.error.qbtes_panic.cause1.action1')]
      }
    ]
  }
];

export const getEngineError = (i18n, engineErrorCode, engineErrorMessage = null) => {
  return getEngineErrors(i18n, engineErrorMessage).find((error) => error.code === engineErrorCode);
};
