import { useLingui } from '@lingui/react';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  HEADLOSS_WARNINGS,
  UNIT
} from '../../../../../../../../../server/constants';
import { fetchGeostorageHeadLossData } from '../../../../../../../api/configSst.api';
import FittingsModal from '../../../../../../../components/FittingsModal/FittingsModal';
import Section from '../../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../../contexts/PopupContext';
import {
  formatValueWithUnit,
  getArraySum,
  isOutOfRange,
  roundUp
} from '../../../../../../../utils/data.utils';
import './SegmentsHeadLossSection.css';

const initialFittingsModalState = {
  isOpen: false,
  segmentIndex: -1,
  segmentName: undefined,
  defaultFittings: undefined,
  initialFittings: undefined
};

const SegmentsHeadLossSection = ({ onLoading, onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [fittingsModal, setFittingsModal] = useState({
    ...initialFittingsModalState
  });
  //#endregion

  //#region [methods]
  const formatValue = (value, unit, precision) =>
    formatValueWithUnit(i18n, value, unit, precision);

  const handleFittingsModalClose = () => {
    setFittingsModal(() => ({ ...initialFittingsModalState }));
  };

  const handleFittingsModalValidate = async (fittings) => {
    try {
      onLoading(true);
      const { ConfigSstID, Data } = config.ConfigsSst[0];
      const { headLossData } = Data.geostorage;
      const { segmentIndex } = fittingsModal;

      // on récupère les résultats du tronçon et les résultats principaux qui dépendent des nouvelles singularités
      const body = {
        segmentFrom: headLossData.segments[segmentIndex].from,
        segmentTo: headLossData.segments[segmentIndex].to,
        fittings
      };
      const data = await fetchGeostorageHeadLossData(ConfigSstID, body);

      // on met à jour le segment avec les nouveaux résultats et les résultats principaux dans la config
      headLossData.segments[segmentIndex] = data.segment;
      headLossData.summary = data.summary;
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    } finally {
      onLoading(false);
      handleFittingsModalClose();
    }
  };
  //#endregion

  //#region [render]
  const { segments } = config.ConfigsSst[0].Data.geostorage.headLossData;
  return (
    <Section
      title={i18n._('config.geostorage.headLoss.segments')}
      level={2}
      open
      className='segments-headLoss-section'
    >
      <div className='custom-table-wrapper'>
        <table className='custom-table'>
          <thead>
            <tr>
              <th>{i18n._('config.geostorage.headLoss.segment')}</th>
              <th>{i18n._('config.geostorage.headLoss.flowRate')}</th>
              <th>{i18n._('config.geostorage.headLoss.fluidPathDistance')}</th>
              <th>{i18n._('config.geostorage.headLoss.volume')}</th>
              <th>{i18n._('config.geostorage.headLoss.fluidAverageTemp')}</th>
              <th>{i18n._('config.geostorage.headLoss.speed')}</th>
              <th>{i18n._('config.geostorage.headLoss.linearHeadLoss')}</th>
              <th>{i18n._('config.geostorage.headLoss.singularities')}</th>
              <th>{i18n._('config.geostorage.headLoss.singularHeadLoss')}</th>
              <th>{i18n._('config.geostorage.headLoss.headLossTotal')}</th>
            </tr>
          </thead>
          <tbody>
            {segments.map((segment, index) => {
              const segmentName = i18n._('config.geostorage.headLoss.points', {
                from: i18n._(`geostorage.segmentPoint.${segment.from}`),
                to: i18n._(`geostorage.segmentPoint.${segment.to}`)
              });
              const isOutOfSpeed = isOutOfRange(
                segment.fluidSpeed,
                HEADLOSS_WARNINGS.GEOSTORAGE_FLUIDSPEED_RANGE
              );
              const linearHeadLoss = roundUp(segment.linearHeadLoss, 2);
              const singularHeadLoss = roundUp(segment.singularHeadLoss, 2);
              const headLossTotal = linearHeadLoss + singularHeadLoss;
              return (
                <tr key={'segment_' + index}>
                  <td>{segmentName}</td>
                  <td>
                    {formatValue(
                      segment.flowRate,
                      UNIT.CUBIC_METER_PER_HOUR,
                      1
                    )}
                  </td>
                  <td>
                    {formatValue(segment.fluidPathDistance, UNIT.METER, 0)}
                  </td>
                  <td>{formatValue(segment.volume, UNIT.CUBIC_METER, 2)}</td>
                  <td>
                    {formatValue(
                      segment.fluidTemperature,
                      UNIT.CELSIUS_DEGREE,
                      0
                    )}
                  </td>
                  <td className={isOutOfSpeed && 'warning-cell'}>
                    {formatValue(segment.fluidSpeed, UNIT.METER_PER_SECOND, 2)}
                  </td>
                  <td>
                    {formatValue(linearHeadLoss, UNIT.WATER_COLUMN_METER, 2)}
                  </td>
                  <td>
                    <Button
                      onClick={() =>
                        setFittingsModal({
                          isOpen: true,
                          segmentName,
                          segmentIndex: index,
                          defaultFittings: segment.defaultFittings,
                          initialFittings: segment.fittings
                        })
                      }
                      className='geostorage-edit-fittings-btn'
                    >
                      {i18n._('config.geostorage.fittings.edit', {
                        count: getArraySum(Object.values(segment.fittings))
                      })}
                    </Button>
                  </td>
                  <td>
                    {formatValue(singularHeadLoss, UNIT.WATER_COLUMN_METER, 2)}
                  </td>
                  <td>
                    {formatValue(headLossTotal, UNIT.WATER_COLUMN_METER, 2)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {fittingsModal.isOpen && (
        <FittingsModal
          isOpen={fittingsModal.isOpen}
          defaultFittings={fittingsModal.defaultFittings}
          initialFittings={fittingsModal.initialFittings}
          subtitle={i18n._('config.geostorage.headLoss.segmentName', {
            segmentName: fittingsModal.segmentName
          })}
          onClose={handleFittingsModalClose}
          onValidate={handleFittingsModalValidate}
        />
      )}
    </Section>
  );
  //#endregion
};

export default SegmentsHeadLossSection;
