import {
  faChevronDown,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { Fragment, useContext } from 'react';
import {
  EQUIPMENT_FAMILY,
  MODULES_WARNINGS,
  UNIT
} from '../../../../../../../server/constants';
import WarningBadge from '../../../../../components/WarningBadge';
import ConfigsContext from '../../../../../contexts/ConfigsContext';
import {
  getTableValue,
  isArrNullOrEmpty,
  isOutOfRange
} from '../../../../../utils/data.utils';
import SubEquipmentRow from './SubEquipmentRow';

const GeostorageRows = ({ subEquipementList, handleShowSubEquipment }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  //#endregion

  //#region [render]
  const { services, geostorage } = config.ConfigsSst[0].Data;
  if (!geostorage.lowerModules || !geostorage.upperModules) return;

  const isInjectionPresent = !!geostorage.settings.source.injection;
  const isInjectionUpperModule = !!geostorage.upperModules.injection;
  // on vérifie que les prix du module haut injection sont bien présents au catalogue (et donc comptés)
  const isInjectionPriceComplete =
    !isOutOfRange(
      services.upperModules.condensor.nominalDiameter,
      MODULES_WARNINGS.UPPER_MODULES_INJECTION_ND_RANGE
    ) &&
    !isOutOfRange(
      services.upperModules.evaporator.nominalDiameter,
      MODULES_WARNINGS.UPPER_MODULES_INJECTION_ND_RANGE
    );
  return (
    <Fragment>
      {/* Modules bas captage et injection */}
      {Object.entries(geostorage.lowerModules).map(
        ([srcExchange, lowerModule], index) => {
          const lowerModuleRef = `${lowerModule?.name}`;
          const visibleLowerModuleEquipment = lowerModule?.equipment?.filter(
            (equipment) => equipment.family === EQUIPMENT_FAMILY.CLIMATIC
          );
          const hasSubEquipment = !isArrNullOrEmpty(
            visibleLowerModuleEquipment
          );
          const prices = lowerModule?.prices;
          return (
            geostorage.settings.source[srcExchange] && ( // pas de captage ou d'injection dans la config : on n'affiche pas les lignes
              <Fragment key={`${srcExchange}_lowerModule`}>
                {!lowerModule ? (
                  <tr>
                    <td>
                      <WarningBadge
                        tooltipContent={i18n._(
                          'config.equipmentList.warning.noModule'
                        )}
                        tooltipId={'lowerModule' + '_' + srcExchange}
                        portalContainer={document.body}
                        place='bottom'
                        iconSize='small'
                      />
                    </td>
                    <td colSpan={9} className='bold'>
                      {i18n._(`geostorage.lowerModule.${srcExchange}.notFound`)}
                    </td>
                  </tr>
                ) : (
                  <tr
                    key={`geoModule_${lowerModule.name}`}
                    className={
                      (index === 0 || index === 1) && 'equipment-list-separator' // 0 = module captage, 1 = module injection
                    }
                  >
                    <td>
                      {hasSubEquipment ? (
                        <FontAwesomeIcon
                          className='equipment-list-chevron'
                          onClick={() => handleShowSubEquipment(lowerModuleRef)}
                          icon={
                            subEquipementList.includes(lowerModuleRef)
                              ? faChevronDown
                              : faChevronRight
                          }
                        />
                      ) : (
                        <WarningBadge
                          tooltipContent={i18n._(
                            'config.equipmentList.warning.noEquipment'
                          )}
                          tooltipId={'lowerModule' + '_' + srcExchange}
                          portalContainer={document.body}
                          place='bottom'
                          iconSize='small'
                        />
                      )}
                    </td>
                    <td className='bold'>{lowerModule.name}</td>
                    <td colSpan={2} className='bold'>
                      {getTableValue(
                        i18n,
                        prices.moduleEquipmentTotal,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                    <td className='bold'>
                      {getTableValue(
                        i18n,
                        prices.manufacturingTotal,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                    <td>
                      {getTableValue(
                        i18n,
                        prices.hydraulicAndStructure,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                    <td>
                      {getTableValue(
                        i18n,
                        prices.factoryInsulation,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                    <td>
                      {getTableValue(i18n, prices.siteInsulation, UNIT.EURO, 0)}
                    </td>
                    <td>
                      {getTableValue(
                        i18n,
                        prices.equipmentConfig,
                        UNIT.EURO,
                        0
                      )}
                    </td>
                    <td className='bold'>
                      {getTableValue(i18n, prices.total, UNIT.EURO, 0)}
                    </td>
                  </tr>
                )}
                {subEquipementList.includes(lowerModuleRef) && (
                  <SubEquipmentRow list={visibleLowerModuleEquipment} />
                )}
              </Fragment>
            )
          );
        }
      )}
      {/* Module haut injection */}
      {isInjectionPresent && // pas d'injection dans la config : on n'affiche pas la ligne
        (!isInjectionUpperModule ? (
          <tr>
            <td>
              <WarningBadge
                tooltipContent={i18n._('config.equipmentList.warning.noModule')}
                tooltipId={'injectionUpperModule'}
                portalContainer={document.body}
                place='bottom'
                iconSize='small'
              />
            </td>
            <td colSpan={9} className='bold'>
              {i18n._('config.geostorage.upperModule.notFound')}
            </td>
          </tr>
        ) : (
          <tr>
            <td>
              {!isInjectionPriceComplete && (
                <WarningBadge
                  tooltipContent={i18n._(
                    'config.equipmentList.warning.incompletePrice'
                  )}
                  tooltipId={'injectionUpperModule'}
                  portalContainer={document.body}
                  place='bottom'
                  iconSize='small'
                />
              )}
            </td>
            <td colSpan={3} className='bold'>
              {geostorage.upperModules.injection.name}
            </td>
            <td className='bold'>
              {getTableValue(
                i18n,
                geostorage.upperModules.injection.price,
                UNIT.EURO,
                0
              )}
            </td>
            <td>
              {getTableValue(
                i18n,
                geostorage.upperModules.injection.price,
                UNIT.EURO,
                0
              )}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td className='bold'>
              {getTableValue(
                i18n,
                geostorage.upperModules.injection.price,
                UNIT.EURO,
                0
              )}
            </td>
          </tr>
        ))}
    </Fragment>
  );
};

export default GeostorageRows;
