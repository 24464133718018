import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';

const ICON_SIZE = {
  small: '14px',
  medium: '15px',
  large: '19px'
};

const WarningBadge = ({
  tooltipContent,
  tooltipId,
  portalContainer,
  place,
  iconSize
}) => {
  //#region [render]
  return (
    <div data-tooltip-content={tooltipContent} data-tooltip-id={tooltipId}>
      <FontAwesomeIcon
        style={{ height: ICON_SIZE[iconSize] }}
        icon={faTriangleExclamation}
      />
      {createPortal(
        <Tooltip
          id={tooltipId}
          place={place}
          arrowColor='#222222'
          opacity={1}
          style={{ zIndex: 3 }}
        />,
        portalContainer
      )}
    </div>
  );
  //#endregion
};

export default WarningBadge;
