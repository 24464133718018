import React from 'react';
import SectionTitle from '../SectionTitle';
import './Bloc.css';

const Bloc = ({ title, children, className, level, help = null }) => {
  return (
    <div className={`bloc${className ? ' ' + className : ''}`}>
      <SectionTitle level={level}>
        {title}
        {help && <span className='bloc-help'>{help}</span>}
      </SectionTitle>
      <div className='bloc-children'>{children}</div>
    </div>
  );
};

export default Bloc;
